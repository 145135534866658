.limites-pix{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--text-primary);
}

.limites-pix .body {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.limites-pix .header a{
    text-decoration: none;
}

.limites-pix-container{
    height: 100%;
}

.limites-pix .limites-pix-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.limites-pix .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.limites-pix .header .col-xl-12 a{
    color: var(--auxiliary-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.limites-pix .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.limites-pix .button-area{
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.limites-pix .button-area button{
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

}
.button-area {
    display: flex;
    flex-direction: row; /* Alinha os botões horizontalmente */
    gap: 10px; /* Espaço entre os botões */
}
.button-area button {
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 2px solid transparent; /* Remove a borda padrão, será definido por classes específicas */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.limites-pix .button-area .confirm-button{
    border-radius: 4px;
    border: 2px solid var(--pix-limits-confirm-button-border);
    background: var(--pix-limits-confirm-button-background);
    color: var(--pix-limits-confirm-button-color);
}
.button-area .confirm-button{
    border-radius: 4px;
    border: 2px solid var(--pix-limits-confirm-button-border);
    background: var(--pix-limits-confirm-button-background);
    color: var(--pix-limits-confirm-button-color);
}

.limites-pix .button-area .reset-button{
    border-radius: 4px;
    border: 2px solid var(--pix-limits-cancel-button-border);
    background: var(--pix-limits-cancel-button-background);
    color: var(--pix-limits-cancel-button-color);
}

.limites-pix .button-area button:disabled{
    background: var(--disabled-color);
    border: 1px solid var(--disabled-color);
}

.limites-pix .mb-20 {
    margin-bottom: 20px;
}

.limites-pix .input-field{
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.limites-pix .input-field label {
    padding-bottom: 5px;
}
.limites-pix .input-field input {
    padding: 7px 13px;
    border-radius: 5px;
    border: 1px solid var(--text-primary) !important;
}
.limites-pix .input-field a {
    color: var(--primary-color);
    padding-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
}

.limites-pix .mb-20 {
    margin-bottom: 20px;
}

.limites-pix .input-field label {
    padding-bottom: 5px;
}
.limites-pix .input-field input {
    padding: 7px 13px;
    border-radius: 5px;
    border: 0;
}
.filter-item {
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: var(--bg-color);
    display: flex;
    height: 38px;
    align-items: center;
    margin-right: 15px;
  }
  
  .filter-item svg {
    margin-left: 10px;
  }
  
  .filter-item input,
  .filter-item select {
    background: var(--bg-color) !important;
    color: var(--text-primary) !important;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    margin-left: 10px;
    border-radius: 4px;
  }
  .input-field input {
    padding: 7px 13px;
    border-radius: 5px;
    border: 1px solid var(--text-primary) !important;
    margin-bottom: 10px;
}

 .link-disabled{ 
    color:var(--text-primary); 
    cursor: not-allowed !important;
    text-decoration: none;   
}

.link-enabled {
    color: initial;
    cursor: pointer; 
    text-decoration: underline;
}

